import React, { Component } from 'react';
import Header from '../layout/Header';

export class Contact_01 extends Component {
    render() {
        
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Fale com a gente</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>Contato</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <section className="ttm-row zero-padding-section clearfix">
                    <div className="container-xl">
                    <div className="row no-gutters">{/* row */}
                        <div className="col-lg-5">
                        <div className="spacing-9">
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                            <div className="title-header">
                                <h5>There’s no place like 127.0.0.1</h5>
                                <h2 className="title">Endereço</h2>
                            </div>
                            </div>{/* section title end */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style2 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                    <i className="ti-location-pin" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                    <h5>Sede própria</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                    <p>Rua Estevam de Araújo Almeida, 476</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                            <div className="sep-line mt-4 mb-4" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style2 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                    <i className="fa fa-phone" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                    <h5>Telefone</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                    <p>+55 11 2072-9203</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                            <div className="sep-line mt-4 mb-4" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style2 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                    <i className="ti ti-email" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                    <h5>Comercial</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                    <p>comercial@4solutions.com.br</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-7">
                        <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                            <div className="ttm-bg-layer-inner" />
                            </div>
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                            <div className="title-header">
                                <h5>Fale com o nosso time</h5>
                                <h2 className="title">Envie sua mensagem</h2>
                            </div>
                            </div>{/* section title end */}
                            <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="#">
                            <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                <input name="name" type="text" className="form-control bg-white" placeholder="Nome *" required="required" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                <input name="phone" type="text" placeholder="Telefone *" required="required" className="form-control bg-white" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                <input name="address" type="text" placeholder="Email *" required="required" className="form-control bg-white" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                <input name="subject" type="text" placeholder="Assunto" required="required" className="form-control bg-white" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                <textarea name="Massage" rows={5} placeholder="Escreva aqui..." required="required" className="form-control bg-white" defaultValue={""} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-left">
                                <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" value>
                                    Enviar!
                                </button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
                <section className="ttm-row map-section res-991-p-0 clearfix">
                    <div className="map-wrapper">
                    
                        <iframe height="100%" src="https://maps.google.com/maps?q=Rua%20Estevam%20de%20Araujo%20Almeida,%20476&t=k&z=19&ie=UTF8&iwloc=&output=embed" />
                    </div>
                </section>
            </div>
            )
    }
}


export default Contact_01;