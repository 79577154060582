import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// importando as tabs 
import CountUp from 'react-countup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FadeIn from 'react-fade-in';
import CarouselPage2 from './banner/Home2_banner';




const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    return (
      <div className="site-main">
        <Header />
        {/* home banner */}
        <CarouselPage />{/* home banner end*/}
        {/* service-section */}
        
        <section className="ttm-row service-section position-relative z-1 clearfix" id="home">
          <div className="container-xl">
            <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
              <div className="col-md-4">
                <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                  <div className="featured-icon">{/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                      <i className="flaticon flaticon-system" />{/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">{/*  featured-content */}
                    <div className="featured-title">{/*  featured-title */}
                      <h5>Alta disponibilidade</h5>
                    </div>
                    <div className="featured-desc">{/*  featured-desc */}
                      <p>em qualquer lugar.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                  <div className="featured-icon">{/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-clock" />{/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">{/*  featured-content */}
                    <div className="featured-title">{/*  featured-title */}
                      <h5>Monitoramento completo</h5>
                    </div>
                    <div className="featured-desc">{/*  featured-desc */}
                      <p>do que realmente importa.</p>
                    </div>
                  </div>
                </div>{/*  featured-icon-box END */}
              </div>
              <div className="col-md-4">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                  <div className="featured-icon">{/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                      <i className="flaticon flaticon-developer" />{/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">{/*  featured-content */}
                    <div className="featured-title">{/*  featured-title */}
                      <h5>Experiência e visão</h5>
                    </div>
                    <div className="featured-desc">{/*  featured-desc */}
                      <p>disrruptiva para o futuro.</p>
                    </div>
                  </div>
                </div>{/*  featured-icon-box END */}
              </div>
            </div>
          </div>
        </section>
        {/* service-section end */}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix" id="about">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>the power of cloud</h5>
                    <h2 className="title">Oferecemos soluções integradas há 14 anos.</h2>
                  </div>
                  <div className="title-desc">
                    <p>Somos especialistas em nuvem. Acreditamos que a tecnologia é um dos pilares para o sucesso dos nossos clientes, por isso, desde 2008, oferecemos soluções tecnológicas de todos os tamanhos e complexidades, de ponta a ponta, atendendo negócios nos mais diversos segmentos e porte, os tornando mais conectadas, seguras e competitivas em seus mercados.</p>
                    <p>Estamos comprometidos em apoiar nossos clientes com transparência e proximidade, entendendo os desafios impostos por um mundo cada vez mais conectado, inovando com soluções customizadas, aprimoramento dos processos de atendimento e treinamentos constantes. </p>
                    <p>Os pilares que fazem parte da nossa cultura:</p>
                  </div>

                </div>{/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Estamos sempre em evolução</span></li>
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Proximidade com o cliente</span></li>
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">É querer resolver</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Senso de urgência</span></li>
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Amor pelo que fazemos</span></li>
                      <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Transparência e Ética</span></li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                {/* <div className="fs-16 ttm-textcolor-darkgrey">
                  <span>Venha fazer parte do nosso time: <a className="fw-500 ttm-textcolor-darkgrey"><u>comercial: </u></a></span> <strong className="fs-18">11 2072-9203</strong>
                </div> */}
              </div>
              <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src="../images/single-img-one.png" title="Oferecemos soluções integradas há 14 anos." alt="Oferecemos soluções integradas há 14 anos." />
                  </div>{/* ttm_single_image-wrapper end */}
                  {/*featured-icon-box*/}
                  {/* <div className="about-overlay-01">
                    <p className="mb-1">
                      Aqui na 4S, somos movidos por desafios e buscamos soluções que atendam às necessidades de nossos clientes e os deem destaque no mercado em que atuam.</p>
                    <br></br>
                    <b>Augusto Schiavelli</b>
                    <br></br>
                    <span>co-foundador</span>

                  </div> */}
                  {/* featured-icon-box end*/}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* tab section */}
        <section className="ttm-row tab-section mt_60  clearfix" id="solucoes">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Soluções integradas</h5>
                    <h2 className="title">Customizamos soluções de acordo com<br /> as necessidades do do seu projeto.</h2>
                    <p>
                      Entregamos soluções de TI completas, garantindo o melhor da tecnologia, para que você tenha sempre<br />o que há de mais inovador para apoiar e alavancar seus negócios.
                    </p>
                  </div>
                </div>{/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style1">
                  <Tabs defaultIndex={0} onSelect={index => console.log(index)}>{/* tabs */}
                    <TabList className="tabs">
                      <Tab className="tab" title="Cloud"><span><i className="flaticon flaticon-report" />Cloud Managed</span></Tab>
                      <Tab className="tab" title="Outsourcing de TI"><span><i className="flaticon flaticon-computer" /> Full IT Outsourcing</span></Tab>
                      <Tab className="tab" title="Data-Center"><span><i className="flaticon flaticon-smartphone" />Data-center</span></Tab>
                      <Tab className="tab" title="Monitoring"><span><i className="flaticon flaticon-computer" />Monitoring</span></Tab>
                    </TabList>
                    <div className="box-shadow content-tab">
                      <FadeIn>
                        <TabPanel>
                          <div className="content-inner">
                            <div className="row">
                              <div className="col-lg-6">
                              <div className="text-left">
                                  <h3 className="title fs-30">Cresça e escale seu negócio com a nuvem.</h3>
                                  <p>Profissionais altamente capacitados com foco em entender as necessidades do negócio e buscar as melhores soluções. Um time engajado em fazer parte da rotina da sua equipe para entregar os resultados que a sua área precisa.</p>
                                  <p>Todo o processo será desenvolvido pelo nosso time, de ponta a ponta. Estamos no data center mais interconectado do mundo, com uma estrutura capaz de atender projetos escaláveis e também com os maiores players do mercado como AWS, GCP, Azure entre outros.</p>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Redução de custos CAPEX iniciais</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Escalabilidade</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Agilidade</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Integração</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Segurança</span>
                                    </li>
                                  </ul>
                                  <br></br>
                                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Quero minha empresa na nuvem<i className="ti ti-arrow-circle-right" /></a>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="grey-border res-991-mt-30 text-left">
                                  {/* ttm_single_image-wrapper */}
                                  <div className="ttm_single_image-wrapper with-border15">
                                    <img className="img-fluid grey-boder" src="../images/cloudcomputing.png" title="single-img-three" alt="single-img-three" />
                                  </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>
                            </div>{/* row end */}
                          </div>{/* content-inner */}{/* row end*/}
                        </TabPanel>
                        <TabPanel>
                          <div className="content-inner">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="grey-border text-left">
                                  {/* ttm_single_image-wrapper */}
                                  <div className="ttm_single_image-wrapper with-border15">
                                    <img className="img-fluid grey-boder" src="../images/gestaoti.png" title="single-img-three" alt="single-img-three" />
                                  </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="text-left res-991-mt-30">
                                  <h3 className="title fs-30">Cuidamos da operação para você cuidar da estratégia.</h3>
                                  <p>Conte com uma empresa parceira para ser a extensão da sua própria equipe, atuando de maneira assertiva e minimizando impactos na operação. 
                                    Ao contratar nossos especialistas dedicados ao seu negócio, você contará com uma gestão proativa e vivenciará uma experiência digital única.</p>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Gestão completa de CAPEX / OPEX</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Controle de inventário de hardware e software</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Automações de instalações</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Suporte completo ao ambiente</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Gestão de contratos</span>
                                    </li>

                                  </ul>
                                  <br></br>
                                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Quero a 4S gerenciando minha TI!<i className="ti ti-arrow-circle-right" /></a>
                                </div>
                              </div>
                            </div>{/* row end */}
                          </div>{/* content-inner */}{/* row end*/}
                        </TabPanel>
                        <TabPanel>
                          <div className="content-inner active">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="text-left">
                                  <h3 className="title fs-30">Estamos no maior e mais interconectado datacenter do mundo.</h3>
                                  <p>Possuímos uma estrutura de hardware próprios, com redundâncias físicas e lógicas, que estão instalados no data center da Equinix, em São Paulo, capaz de garantir alta performance e disponibilidade para nossos clientes em projetos personalizados para suas necessidades. </p>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Investimento inicial ZERO</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Escalabilidade</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Gestão aproximada</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Preço competitivo</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Segurança</span>
                                    </li>
                                  </ul>
                                  <br></br>
                                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Personalize o seu projeto com a 4S!<i className="ti ti-arrow-circle-right" /></a>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="grey-border res-991-mt-30 text-left">
                                  {/* ttm_single_image-wrapper */}
                                  <div className="ttm_single_image-wrapper with-border15">
                                    <img className="img-fluid grey-boder" src="../images/datacenter.png" title="single-img-three" alt="single-img-three" />
                                  </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>
                            </div>{/* row end */}
                          </div>{/* content-inner */}{/* row end*/}
                        </TabPanel>
                        <TabPanel>
                          <div className="content-inner">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="grey-border text-left">
                                  {/* ttm_single_image-wrapper */}
                                  <div className="ttm_single_image-wrapper with-border15">
                                    <img className="img-fluid grey-boder" src="../images/monitoramento.png" title="single-img-three" alt="single-img-three" />
                                  </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="text-left res-991-mt-30">
                                  <h3 className="title fs-30">Monitoramento personalizado do que realmente importa.</h3>
                                  <p>Atuamos com o que há de mais moderno em monitoramento de ambiente tecnoógico e estamos em constante evolução humana para colaborar com o monitoramento de ambientes 
                                    dos nossos clientes. Com uma atuação pró-ativa, buscamos sempre minimizar os downtimes inesperados com ações rápidas e focadas na resolução e prevenção de casos.</p>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Monitoramento automatizado </span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">365 dias por ano, 24horas por dia</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Previsibilidade</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Alertas personalizados </span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Integração com telefonia IP</span>
                                    </li>
                                  </ul>
                                  <br></br>
                                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Quero monitorar meu ambiente!<i className="ti ti-arrow-circle-right" /></a>
                                
                                </div>
                              </div>
                            </div>{/* row end */}
                          </div>{/* content-inner */}{/* row end*/}
                        </TabPanel>
                        <TabPanel>
                          <div className="content-inner">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="text-left">
                                  <h3 className="title fs-30">Infraestrutura de ponta com custo inincial zero.</h3>
                                  <p>Seja qual for o tamanho, aplicação ou etapa do ciclo de vida, oferecemos serviços e soluções que simplificam a alta complexidade dos data centers para acelerar as entregas de TI e evitar indisponibilidade.</p>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Complete software platform development </span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Practice maturity in hardware, firmware and middleware</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Device and field testing for electronics</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Device and field testing for electronics </span>
                                    </li>
                                    <li>
                                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                                      <span className="ttm-list-li-content">Practice maturity in hardware, firmware and middleware</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="grey-border res-991-mt-30 text-left">
                                  {/* ttm_single_image-wrapper */}
                                  <div className="ttm_single_image-wrapper with-border15">
                                    <img className="img-fluid grey-boder" src="../images/datacenter.png" title="single-img-three" alt="single-img-three" />
                                  </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>
                            </div>{/* row end */}
                          </div>{/* content-inner */}{/* row end*/}
                        </TabPanel>
                      </FadeIn>
                    </div>
                  </Tabs>  {/* tabs end*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END tab section */}

        {/* aboutus-section end */}
        {/* history-section */}
       
        {/* history-section end*/}
        {/* zero-padding-section */}

        <section className="ttm-row zero-ttm-row mt_60 res-991-mt-0 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-6">
                {/* col-bg-img-one */}
                <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                </div>
                {/* Responsive View image */}
                <img src="/../images/banner_fazemos.png" className="ttm-equal-height-image img-fluid" alt="col-bgimage-1" />
              </div>
              <div className="col-lg-6 z-9">
                <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                  <div className="spacing-1">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>Sem empurra, empurra. Foco na solução!</h5>
                        <h2 className="title">Entregamos soluções de ponta a ponta.</h2>
                      </div>

                    </div>{/* section title end */}
                    <div className="row mt-40">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">{/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-intelligent" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">{/* featured title */}
                              <h5>Foco na solução</h5>
                            </div>
                            <div className="featured-desc">{/* featured desc */}
                              <p>Sem empurra, empurra. Temos um problema? Vamos solucioná-lo da melhor forma possível.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">{/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-code" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">{/* featured title */}
                              <h5>Automações a nosso favor</h5>
                            </div>
                            <div className="featured-desc">{/* featured desc */}
                              <p>Automatizamos processos e tarefas com o objetivo de otimizarmos o tempo de implantações.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">{/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-process" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">{/* featured title */}
                              <h5>Inovação para facilitar</h5>
                            </div>
                            <div className="featured-desc">{/* featured desc */}
                              <p>Estamos sempre inovando para aprimorar processos que facilitem a operação de nossos clientes.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* zero-padding-section end */}
        {/* clients session */}
        <section className="element-row element-style clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Relações humanas e próximas</h5>
                    <h2 className="title">Entregamos resultados para<br /> clientes incríveis.</h2>
                  </div>
                </div>{/* section title end */}
              </div>
            </div>
          </div>
          <div className=" res-991-p-0 ttm-bgcolor-darkgrey ttm-bg clearfix">
            <div className="container-xl">
              <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
              <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="PMAN">
                        <img className="img-fluid" src="../images/cliente-pman.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="SQUID">
                        <img className="img-fluid" src="../images/cliente-squid.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="QUESTTONÓ">
                        <img className="img-fluid" src="../images/cliente-questtono.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="ABLAB">
                        <img className="img-fluid" src="../images/cliente-ablab.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="COMERCIAL PATY">
                        <img className="img-fluid" src="../images/cliente-cpaty.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="GISMAR">
                        <img className="img-fluid" src="../images/cliente-gismar.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="ZOLY">
                        <img className="img-fluid" src="../images/cliente-zoly.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="DIGESTO">
                        <img className="img-fluid" src="../images/cliente-digesto.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-box-col-wrapper">
                  <div className="client-box ttm-box-view-boxed-logo">
                    <div className="client">
                      <div className="ttm-client-logo-tooltip" data-tooltip="MANUMA">
                        <img className="img-fluid" src="../images/cliente-manuma.svg" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>


              </Slider>
            </div>
          </div>
        </section>
        {/* END clients session */}
        {/* team-section */}
        <section className="ttm-row team-section mt_60 res-991-pt-0 clearfix">
          <div className="container-xl">
            <div className="row align-items-center mb-5">
              <div className="col-lg-10 res-991-text-center">
                <div className="section-title style2 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>As pessoas por trás das conexões</h5>
                    <h2 className="title">Conheça o nosso time</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey" href="mailto:talentos@4solutions.com.br">Faça parte</a>
              </div>
              {/* <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">O talento humano inspira a conexões entre pessoas.</div>
              </div> */}
            </div>
            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
              <div className="ttm-box-col-wrapper">
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">{/* featured-thumbnail */}
                    <img className="img-fluid" src="../images/team-guto.png" alt="Guto" />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">{/* social-icons */}
                        <li className="social-linkedin"><a href='https://www.linkedin.com/in/augustoschiavelli/' target={'_blank'} ><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">{/* featured-title */}
                      <h5>Augusto Schiavelli</h5>
                    </div>
                    <p className="category">co-fundador + new business</p>{/* category */}
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">{/* featured-thumbnail */}
                    <img className="img-fluid" src="../images/team-david.png" alt="David" />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">{/* social-icons */}
                        <li className="social-linkedin"><a href='https://www.linkedin.com/in/david-melo-00325365/' target={'_blank'} ><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">{/* featured-title */}
                      <h5>David Melo</h5>
                    </div>
                    <p className="category">co-founder + cloud architect</p>{/* category */}
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">{/* featured-thumbnail */}
                    <img className="img-fluid" alt="" src="../images/team-robson.png" />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">{/* social-icons */}
                        <li className="social-linkedin"><a href='https://www.linkedin.com/in/robson-silva-a4823949/' target={'_blank'}  ><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">{/* featured-title */}
                      <h5>Robson Silva</h5>
                    </div>
                    <p className="category">support team</p>{/* category */}
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">{/* featured-thumbnail */}
                    <img className="img-fluid" alt="" src="../images/team-carlos.png" />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">{/* social-icons */}
                        <li className="social-linkedin"><a href='https://www.linkedin.com/in/carlos-canda-217064203/' target={'_blank'}  ><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">{/* featured-title */}
                      <h5>Carlos Canda</h5>
                    </div>
                    <p className="category">support team</p>{/* category */}
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">{/* featured-thumbnail */}
                    <img className="img-fluid" alt="" src="../images/team-aurelio.png" />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">{/* social-icons */}
                        <li className="social-linkedin"><a  href='https://www.linkedin.com/in/aur%C3%A9lio-briano-78a4b1129/' target={'_blank'} ><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">{/* featured-title */}
                      <h5>Aurélio Briano</h5>
                    </div>
                    <p className="category">support team</p>{/* category */}
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </section>
        {/* team-section end*/}
        {/* first-row-title-section */}

        {/* first-row-title-section end*/}
        {/* testimonial-section */}

        {/* testimonial-section end */}
        {/* process-section */}

        {/* portfolio-section */}


        {/* process-section end */}
        {/* blog-section */}

        {/* blog-section end */}
      </div>
    )
  }
}


export default Home;
