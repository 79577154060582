import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
    "mdbreact";

export class CarouselPage extends Component {
    render() {
        return (
            <MDBContainer fluid>
                <MDBRow>
                    <MDBCarousel
                        activeItem={1}
                        length={2}
                        showControls={true}
                        showIndicators={false}
                        id="Home_banner"
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <MDBView>
                                    <img className="d-block w-100" src="../../../images/banner_cloud.png" alt="First slide" />
                                </MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner1">
                                            <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                <h2 className="ttm-textcolor-darkgrey flipInY">Traga sua empresa para a <strong className="ttm-textcolor-skincolor">nuvem</strong>.</h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="1.2s" >
                                                <p className="text">Amplie as possibilidades  preparados para apoiar sua empresa na jornada<br /> para a nuvem, com métodos aprimorados de IaC.</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="1.4s" >
                                                <p>
                                                <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Fale com nosso time e vamos juntos!<i className="ti ti-arrow-circle-right" /></a>
                                                    {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white">Nos conte seu projeto</button>&nbsp; &nbsp; */}
                                                    {/* <button className="ttm-btn btn-inline ttm-btn-size-md ttm-textcolor-darkgrey ml-3"><i className="ti-arrow-circle-right" />fale com comercial</button> */}
                                                </p>
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="2">
                                <MDBView>
                                    <img className="d-block w-100" src="../../../images/slider-mainbg-002.jpeg" alt="Second slide" />
                                </MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner2">
                                            <MDBAnimation className="slideInUp" count={1} delay="7s" >
                                                <h2 className="ttm-textcolor-darkgrey">Estamos no mais  <strong className="ttm-textcolor-skincolor">interconectado</strong> data center do mundo.</h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="7.2s" >
                                                <p className="text">Estamos instalados no data center mais interconectado do mundo,<br /> com equipamentos próprios e estrutura redundante.</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="7.4s" >
                                                <p>
                                                    {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">Fale com o comercial</button> */}
                                                    <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Fale com nosso comercial!<i className="ti ti-arrow-circle-right" /></a>                                                    {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white">get altech</button> */}
                                                </p>
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="3">
                                <MDBView>
                                    <img className="d-block w-100" src="../../../images/slider-mainbg-001.jpeg" alt="Second slide" />
                                </MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner2">
                                            <MDBAnimation className="slideInUp" count={1} delay="7s" >
                                                <h2 className="ttm-textcolor-darkgrey">Estamos no mais  <strong className="ttm-textcolor-skincolor">interconectado</strong> data center do mundo.</h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="7.2s" >
                                                <p className="text">Estamos instalados no data center mais interconectado do mundo,<br /> com equipamentos próprios e estrutura redundante.</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="7.4s" >
                                                <p>
                                                    {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">Fale com o comercial</button> */}
                                                    <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-icon-btn-right ttm-bgcolor-skincolor" href={process.env.PUBLIC_URL + '../Contact_01'} >Inicie seu projeto sem investimento inicial!<i className="ti ti-arrow-circle-right" /></a>                                                    {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white">get altech</button> */}
                                                </p>
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default CarouselPage;